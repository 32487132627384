import moment from 'moment'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'

export function dateToString(date) {
    return date.format('yyyy-MM-DD')
}

export function yearToString(date) {
    return date.format('yyyy')
}

export function formatTime(time) {
    return moment(time).format('HH:mm:00');
}

export function stringToDate(date) {
    return moment(date, 'yyyy-MM-DD')
}

export function stringToDateTime(date) {
    return moment(date, 'yyyy-MM-DD hh:mm:ss')
}

export function stringToTime(date) {
    return moment(date, 'HH:mm:ss')
}

export function dateFormate(date) {
    const mDate = stringToDate(date)
    return mDate.format('DD/MM/yyyy')
}

export function dateTimeFormate(date) {
    const mDate = stringToDateTime(date)
    return mDate.format('DD/MM/yyyy hh:mm A')
}

export function timeFormate(date) {
    const mDate = stringToTime(date)
    return mDate.format('hh:mm A')
}

export function dataToMoment(date) {
    return moment(date)
}

export function timeToString(date) {
    return date.format('HH:mm:ss')
}

export function indianNumberFormat(number) {
    return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number)
}

export function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export function trim(string) {
    return ltrim(rtrim(string))
}

export function calculateAge(date) {
    return moment().diff(date, 'years')
}
