import React, { useState, useEffect } from 'react'
import {
    Card,
    CardContent,
    Button,
    Grid,
    TextField,
    LinearProgress,
} from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { apiGet, apiPut } from '../../../../functionsAPI'
import Autocomplete from '@material-ui/lab/Autocomplete'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const MyPreferences = () => {
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    // Alerts
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const handleAlertClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }
    // Helpers
    const [states, setStates] = useState([])
    const [state, setState] = useState(null)
    const [districts, setDistricts] = useState([])
    const [district, setDistrict] = useState(null)
    const [talukas, setTalukas] = useState([])
    const [taluka, setTaluka] = useState(null)
    const [cities, setCities] = useState([])
    const [city, setCity] = useState(null)

    const getStates = () => {
        apiGet('/states/all')
            .then((res) => {
                setStates(res.data)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
            });
    };
    const getDistricts = (stateId) => {
        const params = {
            state_id: stateId
        }
        apiGet('/districts/all', params)
            .then((res) => {
                setDistricts(res.data)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
            });
    };
    const getTaluka = (districtId) => {
        const params = {
            district_id: districtId
        }
        apiGet('/taluka/all', params)
            .then((res) => {
                setTalukas(res.data)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
            });
    };
    const getCities = (talukaId) => {
        const params = {
            taluko_id: talukaId
        }
        apiGet('/cities/all', params)
            .then((res) => {
                setCities(res.data)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
            });
    };
    const getPreferences = () => {
        setLoading(true)
        apiGet('/preferences')
            .then((res) => {
                if (res.data.city !== null) {
                    const _city = res.data.city
                    const _taluko = _city.taluko
                    const _district = _taluko.district
                    const _state = _district.state
                    setState(_state)
                    setDistrict(_district)
                    setTaluka(_taluko)
                    setCity(_city)
                    setLoading(false)
                } else {
                    setState(null)
                    setDistrict(null)
                    setTaluka(null)
                    setCity(null)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    };

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            city_id: city === null ? null : city.id,
        }
        apiPut('/preferences', data)
            .then((res) => {
                setAlertType('success')
                setAlertMessage('Updated successfully')
                setShowAlert(true)
                setLoading(false)
            })
            .catch((err) => {
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    useEffect(() => {
        getStates()
        getPreferences()
        // eslint-disable-next-line
    }, [])


    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card>
                {progress}
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={states}
                                    autoHighlight
                                    className="mt-3"
                                    getOptionLabel={(option) => (option.name_en + ' (' + option.name_gj + ')')}
                                    renderInput={(params) => <TextField {...params} label="State" variant="outlined" />}
                                    onChange={(event, value) => {
                                        setState(value)
                                        if (value !== null) {
                                            getDistricts(value.id)
                                        } else {
                                            setDistricts([])
                                            setTalukas([])
                                            setCities([])
                                        }
                                        setDistrict(null)
                                        setTaluka(null)
                                        setCity(null)
                                    }}
                                    value={state}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={districts}
                                    autoHighlight
                                    className="mt-3"
                                    getOptionLabel={(option) => (option.name_en + ' (' + option.name_gj + ')')}
                                    renderInput={(params) => <TextField {...params} label="District" variant="outlined" />}
                                    onChange={(event, value) => {
                                        setDistrict(value)
                                        if (value !== null) {
                                            getTaluka(value.id)
                                        } else {
                                            setTalukas([])
                                            setCities([])
                                        }
                                        setTaluka(null)
                                        setCity(null)
                                    }}
                                    value={district}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={talukas}
                                    autoHighlight
                                    getOptionLabel={(option) => (option.name_en + ' (' + option.name_gj + ')')}
                                    renderInput={(params) => <TextField {...params} label="Taluko" variant="outlined" />}
                                    onChange={(event, value) => {
                                        setTaluka(value)
                                        if (value !== null) {
                                            getCities(value.id)
                                        } else {
                                            setCities([])
                                        }
                                        setCity(null)
                                    }}
                                    value={taluka}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={cities}
                                    autoHighlight
                                    getOptionLabel={(option) => (option.name_en + ' (' + option.name_gj + ')')}
                                    renderInput={(params) => <TextField {...params} label="City" variant="outlined" />}
                                    onChange={(event, value) => setCity(value)}
                                    value={city}
                                />
                            </Grid>
                        </Grid>
                        <hr />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={loading}
                        >
                            Update
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default MyPreferences
