import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from "react-router-dom"
import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../config'

import ListIcon from '@material-ui/icons/List'
import HomeIcon from '@material-ui/icons/Home'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import SettingsIcon from '@material-ui/icons/Settings'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import PersonIcon from '@material-ui/icons/Person'
import MuseumIcon from '@material-ui/icons/Museum';
import GroupIcon from '@material-ui/icons/Group';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(2),
        minWidth: '40px'
    },
}));

const SideNav = () => {
    const token = localStorage.getItem('ssaToken')
    const classes = useStyles()
    const [settingToggle, setSettingToggle] = useState(false)
    const [operations, setOperations] = useState([])
    let location = useLocation().pathname;
    const [menu, setMenu] = useState([])
    const [urlDashboard, setUrlDashboard] = useState('')
    const [urlMaster, setUrlMaster] = useState('')
    const [urlDonors, setUrlDonors] = useState('')
    const [urlDonations, setUrlDonations] = useState('')
    const [urlReports, setUrlReports] = useState('')
    const [urlUsers, setUrlUsers] = useState('')
    const [urlRoles, setUrlRoles] = useState('')
    const [urlProfile, setUrlProfile] = useState('')

    const getOperations = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/operations'
        axios.get(url, headerConfig).then(response => {
            setOperations(response.data.operations)
            setMenu(response.data.menu)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getOperations()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        activeUnset()
        checkURL()
        // eslint-disable-next-line
    }, [location])

    const checkOperation = (item) => {
        const status = operations.includes(item)
        return status
    }

    const checkMenu = (operation) => {
        const status = menu.includes(operation)
        return status
    }

    const activeUnset = () => {
        setUrlDashboard('')
        setUrlMaster('')
        setUrlDonors('')
        setUrlDonations('')
        setUrlReports('')
        setUrlUsers('')
        setUrlRoles('')
        setUrlProfile('')
        // setUrlAppointment('')
    }

    const checkURL = () => {
        if (location.includes("master")) {
            setUrlMaster('active');
        } else if (location.includes("donors")) {
            setUrlDonors('active');
        } else if (location.includes("donations")) {
            setUrlDonations('active');
        } else if (location.includes("reports")) {
            setUrlReports('active');
        } else if (location.includes("users")) {
            setUrlUsers('active');
        } else if (location.includes("roles")) {
            setUrlRoles('active');
        } else if (location.includes("profile")) {
            setUrlProfile('active');
        } else {
            setUrlDashboard('active');
        }

        if (location.includes("settings")) {
            setSettingToggle(true)
        }
    }

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={`${classes.root} mt-3`}
        >
            <Link to="/dashboard" className={urlDashboard}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Dashboard"><HomeIcon /></Tooltip></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </Link>
            {/* {checkMenu('Master') ? ( */}
            <Link to='/dashboard/master' className={urlMaster}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Master"><MuseumIcon /></Tooltip></ListItemIcon>
                    <ListItemText primary="Master" />
                </ListItem>
            </Link>
            {/* ) : ('')} */}
            {/* {checkMenu('CHANGE_THIS') ? ( */}
            <Link to='/dashboard/donors' className={urlDonors}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Donors"><GroupIcon /></Tooltip></ListItemIcon>
                    <ListItemText primary="Donors" />
                </ListItem>
            </Link>
            {/* ) : ('')} */}
            {/* {checkMenu('CHANGE_THIS') ? ( */}
            <Link to='/dashboard/donations' className={urlDonations}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Donations"><AttachMoneyIcon /></Tooltip></ListItemIcon>
                    <ListItemText primary="Donations" />
                </ListItem>
            </Link>
            {/* ) : ('')} */}
            {/* {checkMenu('CHANGE_THIS') ? ( */}
            <Link to='/dashboard/reports' className={urlReports}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Reports"><ListIcon /></Tooltip></ListItemIcon>
                    <ListItemText primary="Reports" />
                </ListItem>
            </Link>
            {/* ) : ('')} */}
            <ListItem button onClick={() => setSettingToggle(!settingToggle)}>
                <ListItemIcon>
                    <Tooltip title="Settings"><SettingsIcon /></Tooltip>
                </ListItemIcon>
                <ListItemText primary="Settings" />
                {settingToggle ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={settingToggle} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {checkOperation('List Users') ? (
                        <Link to='/dashboard/settings/users' className={urlUsers}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Users"><SupervisorAccountIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>
                        </Link>
                    ) : ('')}
                    {checkOperation('List Roles') ? (
                        <Link to='/dashboard/settings/roles' className={urlRoles}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Roles"><ListIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Roles" />
                            </ListItem>
                        </Link>
                    ) : ('')}
                    <Link to='/dashboard/profile' className={urlProfile}>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon>
                                <Tooltip title="My Profile"><PersonIcon /></Tooltip>
                            </ListItemIcon>
                            <ListItemText primary="My Profile" />
                        </ListItem>
                    </Link>
                </List>
            </Collapse>
        </List>
    )
}

export default SideNav
