import React, { useEffect, useState } from 'react'
import {
    Avatar,
    Breadcrumbs,
    ButtonGroup,
    Card,
    CardContent,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../components/Table'
import BookIcon from '@material-ui/icons/Book'
import ListIcon from '@material-ui/icons/List';
import GroupIcon from '@material-ui/icons/Group';
import Visibility from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles, createStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { URL } from '../config'
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
    createStyles({
        large: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#17A2B8"
        },
        cold: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#f1a649"
        },
        hot: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#429ce1"
        },
        warm: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#6368c0"
        },
        largeIcon: {
            fontSize: 50
        }
    }),
);

const Dashboard = () => {
    const token = localStorage.getItem('ssaToken')
    const classes = useStyles()
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [tableData, setTableData] = useState([
        {
            "id": "71ff0726-4989-4d47-ba58-bf4d6df376c6",
            "date": "25/07/2021",
            "donor_name": "Nareshbhai",
            "receipt_no": "SS/21-22/0001",
            "type": "Seva",
            "amount": "₹100",
            "action":
                <ButtonGroup size="small" aria-label="small outlined button group">
                    <a href={'/#/dashboard/donations/71ff0726-4989-4d47-ba58-bb8d86537637'}>
                        <Tooltip title="View">
                            <IconButton>
                                <Visibility fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </a>
                </ButtonGroup>,
        },
        {
            "id": "71ff0726-4989-4d47-ba58-bb8d86537637",
            "date": "17/04/2021",
            "donor_name": "Rameshbhai",
            "receipt_no": "SS/21-22/0002",
            "type": "Education",
            "amount": "₹300",
            "action":
                <ButtonGroup size="small" aria-label="small outlined button group">
                    <a href={'/#/dashboard/donations/71ff0726-4989-4d47-ba58-bb8d86537637'}>
                        <Tooltip title="View">
                            <IconButton>
                                <Visibility fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </a>
                </ButtonGroup>,
        },
        {
            "id": "71ff0726-4989-4d47-ba58-bb8d86537637",
            "date": "25/07/2021",
            "donor_name": "Jayeshbhai",
            "receipt_no": "SS/21-22/0003",
            "type": "Food",
            "amount": "₹500",
            "action":
                <ButtonGroup size="small" aria-label="small outlined button group">
                    <a href={'/#/dashboard/donations/71ff0726-4989-4d47-ba58-bb8d86537637'}>
                        <Tooltip title="View">
                            <IconButton>
                                <Visibility fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </a>
                </ButtonGroup>,
        },
    ])
    const [tableDataDonors, setTableDataDonors] = useState([
        {
            "id": "71ff0726-4989-4d47-ba58-bf4d6df376c6",
            "donor_name": "Nareshbhai",
            "state_city": "Gujarat, Bhuj",
            "mobile": "9876543210",
            "amount": "₹100",
            "date": "25/07/2021",
            "action":
                <ButtonGroup size="small" aria-label="small outlined button group">
                    <a href={'/#/dashboard/donors/71ff0726-4989-4d47-ba58-bb8d86537637'}>
                        <Tooltip title="View">
                            <IconButton>
                                <Visibility fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </a>
                </ButtonGroup>,
        },
        {
            "id": "71ff0726-4989-4d47-ba58-bb8d86537637",
            "donor_name": "Rameshbhai",
            "state_city": "Gujarat, Bhuj",
            "mobile": "9876543210",
            "amount": "₹300",
            "date": "17/04/2021",
            "action":
                <ButtonGroup size="small" aria-label="small outlined button group">
                    <a href={'/#/dashboard/donors/71ff0726-4989-4d47-ba58-bb8d86537637'}>
                        <Tooltip title="View">
                            <IconButton>
                                <Visibility fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </a>
                </ButtonGroup>,
        },
        {
            "id": "71ff0726-4989-4d47-ba58-bb8d86537637",
            "donor_name": "Jayeshbhai",
            "state_city": "Gujarat, Bhuj",
            "mobile": "9876543210",
            "amount": "₹500",
            "date": "25/07/2021",
            "action":
                <ButtonGroup size="small" aria-label="small outlined button group">
                    <a href={'/#/dashboard/donors/71ff0726-4989-4d47-ba58-bb8d86537637'}>
                        <Tooltip title="View">
                            <IconButton>
                                <Visibility fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </a>
                </ButtonGroup>,
        },
    ])
    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                sort: true,
                // customBodyRender: (value, tableMeta, updateValue) => {
                //     return (value === '') ? '' : dateFormate(value)
                // }
            }
        },
        {
            name: "donor_name",
            label: "Donor Name",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const index = tableMeta.rowIndex
                    const id = tableData[index].id
                    return <Link to={'dashboard/donors/' + id}>{value}</Link>
                }
            }
        },
        {
            name: "receipt_no",
            label: "Receipt No",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const index = tableMeta.rowIndex
                    const id = tableData[index].id
                    return <Link to={'dashboard/donations/' + id}>{value}</Link>
                }
            }
        },
        {
            name: "type",
            label: "Donation Type",
            options: {
                sort: true,
            }
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]
    const columnsDonors = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "donor_name",
            label: "Donor Name",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const index = tableMeta.rowIndex
                    const id = tableData[index].id
                    return <Link to={'dashboard/donors/' + id}>{value}</Link>
                }
            }
        },
        {
            name: "state_city",
            label: "State - City",
            options: {
                sort: true,
            }
        },
        {
            name: "mobile",
            label: "Mobile",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return <a href={'tel:' + value}>{value}</a>
                }
            }
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const index = tableMeta.rowIndex
                    const id = tableData[index].id
                    return <Link to={'dashboard/donations/' + id}>{value}</Link>
                }
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                sort: true,
                // customBodyRender: (value, tableMeta, updateValue) => {
                //     return (value === '') ? '' : dateFormate(value)
                // }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]
    const changePage = (page) => {
        const start = limit * (page)
        // getList(start, limit, sortBy, order, search)
        setStart(start)
    }
    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        // getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }
    const changeRowsPerPage = (limit) => {
        // getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }
    const onSearch = (search) => {
        // getList(start, limit, sortBy, order, search)
        setSearch(search)
    }
    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }
    const [counts, setCounts] = useState({
        appointmentFiles: 150,
        totalFiles: 200,
        totalCustomers: 250,
        pendingFiles: 75,
    })
    const options = {
        responsive: true,
    }
    const [chartData, setChartData] = useState({
        pending: {
            labels: ['5 Jan', '10 Jan', '15 Jan', '20 Jan', '25 Jan', '30 Jan'],
            data: [5, 40, 15, 12, 30, 14],
        },
        fileIn: {
            labels: ['5 Jan', '10 Jan', '15 Jan', '20 Jan', '25 Jan', '30 Jan'],
            data: [15, 20, 25, 27, 20, 24],
        },
        completed: {
            labels: ['5 Jan', '10 Jan', '15 Jan', '20 Jan', '25 Jan', '30 Jan'],
            data: [25, 30, 7, 40, 52, 55],
        }
    })
    const fileData = {
        labels: chartData.pending.labels,
        datasets: [
            {
                label: 'Pending Files',
                data: chartData.pending.data,
                fill: false,
                backgroundColor: 'rgb(87, 210, 225)',
                borderColor: 'rgba(87, 210, 225)',
            },
            {
                label: 'File In',
                data: chartData.fileIn.data,
                fill: false,
                backgroundColor: 'rgb(41, 227, 20)',
                borderColor: 'rgba(41, 227, 20)',
            },
            {
                label: 'Completed Files',
                data: chartData.completed.data,
                fill: false,
                backgroundColor: 'rgb(243, 90, 151)',
                borderColor: 'rgba(243, 90, 151)',
            }
        ],
    };

    const getData = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/dashboard/counts'
        axios.get(url, headerConfig).then(response => {
            // setCounts(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        // getData()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">Dashboard</Typography>
                </Breadcrumbs>
                <p className="mt-2">
                    Welcome to Seva Sadhna - Admin
                </p>
            </div>
            <Grid
                className="entry-div"
                container
                spacing="3"
            >
                <Grid item xs={12} lg={3}>
                    <Card>
                        <CardContent>
                            <Grid
                                container
                                spacing={3}
                                alignItems="center"
                            >
                                <Grid item>
                                    <Avatar
                                        variant="rounded"
                                        className={classes.large}
                                    >
                                        <BookIcon className={classes.largeIcon} />
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="h6"
                                    >
                                        Total Donation
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="h6"
                                    >
                                        {counts.appointmentFiles}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Card>
                        <CardContent>
                            <Grid
                                container
                                spacing={3}
                                alignItems="center"
                            >
                                <Grid item>
                                    <Avatar
                                        variant="rounded"
                                        className={classes.hot}
                                    >
                                        <GroupIcon className={classes.largeIcon} />
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="h6"
                                    >
                                        Total Donors
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="h6"
                                    >
                                        {counts.totalFiles}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Card>
                        <CardContent>
                            <Grid
                                container
                                spacing={3}
                                alignItems="center"
                            >
                                <Grid item>
                                    <Avatar
                                        variant="rounded"
                                        className={classes.warm}
                                    >
                                        <ListIcon className={classes.largeIcon} />
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="h6"
                                    >
                                        This Week Donations
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="h6"
                                    >
                                        {counts.totalCustomers}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Card>
                        <CardContent>
                            <Grid
                                container
                                spacing={3}
                                alignItems="center"
                            >
                                <Grid item>
                                    <Avatar
                                        variant="rounded"
                                        className={classes.cold}
                                    >
                                        <DescriptionIcon className={classes.largeIcon} />
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="h6"
                                    >
                                        Today's Donations
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="h6"
                                    >
                                        {counts.pendingFiles}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Table
                        title={'Last 10 Donations'}
                        serverSide={true}
                        count={count}
                        columns={columns}
                        data={tableData}
                        onTableChange={handleTableChange}
                        rowsPerPage={limit}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Table
                        title={'Last 10 Donors'}
                        serverSide={true}
                        count={count}
                        columns={columnsDonors}
                        data={tableDataDonors}
                        onTableChange={handleTableChange}
                        rowsPerPage={limit}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default Dashboard
