import React, { useEffect, useState } from 'react'
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    LinearProgress,
    Link as MLink,
    Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { apiDelete, apiGet } from '../../functionsAPI';
import PrintIcon from '@material-ui/icons/Print';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ViewDonor = (props) => {
    const donationId = props.match.params.donationId
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    // Alerts
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const handleAlertClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }
    const [donation, setDonation] = useState({})
    const [openDeleteDonation, setOpenDeleteDonation] = useState(false)

    const getDonation = () => {
        setLoading(true)
        apiGet('/API_HERE/' + donationId)
            .then((res) => {
                setDonation(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    useEffect(() => {
        getDonation()
        // eslint-disable-next-line
    }, [])

    // Delete
    const handleOpenDelete = () => {
        setOpenDeleteDonation(true)
    }
    const handleCloseDelete = () => {
        setOpenDeleteDonation(false)
    }
    const deleteDonation = () => {
        setLoading(true)
        apiDelete('/API_HERE/' + donationId)
            .then((res) => {
                setAlertType('success')
                setAlertMessage('Deleted successfully')
                setShowAlert(true)
                setOpenDeleteDonation(false)
                props.history.push('/dashboard/donations')
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">View Donation</Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                            <MLink color="inherit" href="/#/dashboard/donations">
                                Donations
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Button variant="contained" color="primary" className='mr-2'><PrintIcon className='mr-2' />Print</Button>
                        <Button variant="contained" color="secondary" onClick={handleOpenDelete}><DeleteIcon className='mr-2' />Delete</Button>
                    </Grid>
                </Grid>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="entry-div">
                {progress}
                <Card>
                    <CardContent>
                        <div className="before">
                            <h2 className="form-heading">Donation Details: <strong>SS/2021/0004</strong></h2>
                        </div>
                        <hr />
                        <table className="details">
                            <tr>
                                <th>Doner Name</th>
                                <td>Rakesh Chauhan</td>
                                <th>Mobile</th>
                                <td><a href="tel:+91 98765 43215">+91 98765 43215</a></td>
                            </tr>
                            <tr>
                                <th>State</th>
                                <td>Gujarat</td>
                                <th>City</th>
                                <td>Bhuj</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>Near Bus Stand</td>
                                <th>Pincode</th>
                                <td>370001</td>
                            </tr>
                            <tr>
                                <th>PAN Card Number</th>
                                <td>ABCDE4253F</td>
                                <th>Donation Type</th>
                                <td>Seva</td>
                            </tr>
                            <tr>
                                <th>Donation Amount</th>
                                <td>₹100</td>
                                <th>Transaction No</th>
                                <td>TNXCN78329FSK</td>
                            </tr>
                        </table>
                    </CardContent>
                </Card>
                {/* Delete Donation dialog */}
                <Dialog
                    open={openDeleteDonation}
                    onClose={handleCloseDelete}
                >
                    {progress}
                    <DialogTitle id="alert-dialog-title">Delete Donation</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the Donation?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDelete} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={deleteDonation} variant="contained" color="secondary" disabled={loading}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default ViewDonor
