import React, { useState } from 'react'
import { trim } from '../functions'
import { apiGet, apiPost } from '../functionsAPI';
import PropTypes from 'prop-types';
import DonationTypes from './masterData/DonationTypes'
import States from './masterData/States'
import City from './masterData/City'
import AddIcon from '@material-ui/icons/Add';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
    AppBar,
    Button,
    Breadcrumbs,
    Tabs,
    Tab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography,
    Snackbar
} from '@material-ui/core';

// Tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const Master = () => {
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }
    // Helpers
    const [value, setValue] = useState(0);

    // Businesses
    const [openDonationType, setOpenDonationType] = useState(false)
    const [donationType, setDonationType] = useState('')
    const handleOpenDonationType = () => {
        setOpenDonationType(true);
    };
    const handleCloseDonationType = () => {
        setOpenDonationType(false);
    };
    const onDonationTypeSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            name: trim(donationType)
        }
        apiPost('/API_URL', data)
            .then((res) => {
                setAlertType('success')
                setAlertMessage('Added successfully')
                handleCloseDonationType();
                setShowAlert(true)
                setValue(1);
                setValue(0);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    // States
    const [openState, setOpenState] = useState(false)
    const [stateName, setStateName] = useState('')
    const handleOpenState = () => {
        setOpenState(true);
    };
    const handleCloseState = () => {
        setOpenState(false);
    };
    const onStateSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            name: trim(stateName)
        }
        apiPost('/API_URL', data)
            .then((res) => {
                setAlertType('success')
                setAlertMessage('Added successfully')
                handleCloseState();
                setShowAlert(true)
                setValue(0);
                setValue(1);
                setLoading(false)
            })
            .catch((err) => {
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    // City
    const [openCity, setOpenCity] = useState(false)
    const [cityName, setCityName] = useState('')
    const [states, setStates] = useState([]);
    const [state, setState] = useState(null);

    const getStates = () => {
        apiGet('/API_URL/all')
            .then((res) => {
                setStates(res.data)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
            });
    };
    const handleCityChange = (e) => {
        var value = e.target.value
        setCityName(value)
    }
    const handleOpenCity = () => {
        getStates()
        setOpenCity(true);
    };
    const handleCloseCity = () => {
        setStates([])
        setState(null)
        setCityName('')
        setOpenCity(false);
    };
    const onCitySubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            name: trim(cityName),
            state_id: state.id
        }
        apiPost('/API_URL', data)
            .then((res) => {
                setAlertType('success')
                setAlertMessage('Added successfully')
                handleCloseCity();
                setShowAlert(true)
                setValue(0);
                setValue(2);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    const [btnAdd, setBtnAdd] = useState(<Button variant="contained" color="primary" onClick={handleOpenDonationType}><AddIcon fontSize="small" className="mr-2" />Add Donation Type</Button>)
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setBtnAdd(<Button variant="contained" color="primary" onClick={handleOpenDonationType}><AddIcon fontSize="small" className="mr-2" />Add Donation Type</Button>)
        } else if (newValue === 1) {
            setBtnAdd(<Button variant="contained" color="primary" onClick={handleOpenState}><AddIcon fontSize="small" className="mr-2" />Add State</Button>)
        } else if (newValue === 2) {
            setBtnAdd(<Button variant="contained" color="primary" onClick={handleOpenCity}><AddIcon fontSize="small" className="mr-2" />Add City</Button>)
        }
    };

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">Master</Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        {btnAdd}
                    </Grid>
                </Grid>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="entry-div">
                {progress}
                <AppBar position="static">
                    <Tabs value={value} textColor="primary" onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                        <Tab label="Donation Types" {...a11yProps(0)} />
                        <Tab label="States" {...a11yProps(1)} />
                        <Tab label="City" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <div className="mt-5">
                    <TabPanel value={value} index={0}>
                        <DonationTypes />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <States />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <City />
                    </TabPanel>
                </div>
            </div>
            {/* Add Donation Type Model */}
            <Dialog open={openDonationType} onClose={handleCloseDonationType} className="form-action" aria-labelledby="form-dialog-title">
                {progress}
                <DialogTitle id="form-dialog-title">Add New Donation Type</DialogTitle>
                <form onSubmit={onDonationTypeSubmit}>
                    <DialogContent dividers>
                        <TextField required fullWidth variant="outlined" label="Donation Type" onChange={e => setDonationType(e.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDonationType} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={loading} variant="contained" color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {/* Add State Model */}
            <Dialog open={openState} onClose={handleCloseState} className="form-action" aria-labelledby="form-dialog-title">
                {progress}
                <DialogTitle id="form-dialog-title">Add New State</DialogTitle>
                <form onSubmit={onStateSubmit}>
                    <DialogContent dividers>
                        <TextField required fullWidth variant="outlined" label="State Name" onChange={e => setStateName(e.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseState} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={loading} variant="contained" color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {/* Add City Model */}
            <Dialog open={openCity} onClose={handleCloseCity} className="form-action" aria-labelledby="form-dialog-title">
                {progress}
                <DialogTitle id="form-dialog-title">Add New City</DialogTitle>
                <form onSubmit={onCitySubmit}>
                    <DialogContent dividers>
                        <Autocomplete
                            options={states}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="State" required variant="outlined" />}
                            onChange={(event, value) => setState(value)}
                            value={state}
                        />
                        <TextField required fullWidth variant="outlined" className="mt-4" label="City" onChange={handleCityChange} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseCity} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={loading} variant="contained" color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default Master
