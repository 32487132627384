import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    Link as MLink,
    TextField,
    Typography,
    LinearProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { apiGet, apiPut } from '../../functionsAPI';
import { trim } from '../../functions';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Edit = (props) => {
    const donorId = props.match.params.donorId
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    // Alert
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    // Form data
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [state, setState] = useState(null)
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [pincode, setPincode] = useState('')
    const [mobile, setMobile] = useState('')
    const [panCard, setPanCard] = useState('')

    const getStates = () => {
        setLoading(true)
        apiGet('/API_URL/all')
            .then((res) => {
                setStates(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    const getCities = (stateId) => {
        setLoading(true)
        const data = {
            state_id: stateId
        }
        apiGet('/API_URL', data)
            .then((res) => {
                setCities(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    const getDonor = () => {
        setLoading(true)
        apiGet('/API_URL/' + donorId)
            .then((res) => {
                setFirstName(res.data.first_name)
                setLastName(res.data.middle_name)
                setState(res.data.last_name)
                getCities(res.data.state_id)
                setCity(res.data.gender)
                setAddress(res.data.pan_number)
                setPincode(res.data.primary_mobile)
                setMobile(res.data.secondary_mobile)
                setPanCard(res.data.whats_app)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    useEffect(() => {
        getStates()
        getDonor()
        // eslint-disable-next-line
    }, [])

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            firstName: trim(firstName),
            lastName: trim(lastName),
            last_name: trim(lastName),
            state: state,
            city: city,
            address: address,
            pincode: pincode,
            mobile: mobile,
            panCard: panCard
        }
        apiPut('/API_URL/' + donorId, data)
            .then((res) => {
                setAlertType('success')
                setAlertMessage('Updated successfully')
                setShowAlert(true)
                setLoading(false)
                props.history.push('/dashboard/donors/' + donorId)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">Edit Donor</Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/donors">
                        Donors
                    </MLink>
                    <MLink color="inherit" href={"/#/dashboard/donors/" + donorId}>
                        View Donor
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card className="entry-div">
                {progress}
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Form</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    required
                                    label="First Name"
                                    variant="outlined"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    variant="outlined"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Mobile Number"
                                    variant="outlined"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={states}
                                    autoHighlight
                                    value={state}
                                    onChange={(e, newValue) => setState(newValue)}
                                    renderInput={(params) => <TextField {...params} value={state} label="State" variant="outlined" />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={cities}
                                    autoHighlight
                                    value={city}
                                    onChange={(e, newValue) => setCity(newValue)}
                                    renderInput={(params) => <TextField {...params} value={city} label="City" variant="outlined" />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Pincode"
                                    variant="outlined"
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="PAN Card"
                                    variant="outlined"
                                    value={panCard}
                                    onChange={(e) => setPanCard(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label="Address"
                                    variant="outlined"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className="text-center">
                                <hr />
                                <Link to={"/dashboard/donors/" + donorId} className='mr-2'>
                                    <Button variant="contained">
                                        Cancel
                                    </Button>
                                </Link>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
                {progress}
            </Card>
        </div >
    )
}

export default Edit
