import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
    Box,
    Button,
    Container,
    Grid,
    Link as MLink,
    TextField,
    Snackbar,
    Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import isEmail from 'validator/lib/isEmail'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { URL } from '../../config.js'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ForgotPassword(props) {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(false)
    const [email, setEmail] = useState(null)
    const [otp, setOtp] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [cnfNewPassword, setCnfNewPassword] = useState(null)
    const [status, setStatus] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)

    // Alert
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    useEffect(() => {
        const token = localStorage.getItem('ssaToken')
        if (token !== null) {
            var location = '/dashboard'
            props.history.push(location)
        }
        // eslint-disable-next-line
    }, [])

    const emailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
        if (!isEmail(value)) {
            setDisabled(true)
        }
        else {
            setDisabled(false)
        }
    }

    const getOtp = (email) => {
        setDisabled(true)
        const data = {
            email
        }
        const url = URL + '/forgot-password'
        axios.post(url, data).then(response => {
            setAlertType('success')
            setAlertMessage('OTP sent to your email')
            setShowAlert(true)
            setStatus(true)
            setDisabled(false)
        }).catch(error => {
            let message = 'Something went wrong, Please try again later'
            if (error.response) {
                message = error.response.data.detail
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log(error);
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setDisabled(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // document.getElementById('verify').reset()
        getOtp(email)
    }

    const handleReset = (e) => {
        e.preventDefault()
        if (newPassword !== cnfNewPassword) {
            setAlertType('error')
            setAlertMessage('Passwords are not same')
            setShowAlert(true)
        }
        else {
            setDisabled(true)
            const url = URL + '/confirm-forgot-password'
            const data = {
                email,
                otp,
                password: newPassword
            }
            axios.post(url, data).then(response => {
                setAlertType('success')
                setAlertMessage('Password changed successfully.')
                setShowAlert(true)
                setDisabled(false)
                props.history.push('/login')
            }).catch(error => {
                let message = 'Something went wrong, Please try again later'
                if (error.response) {
                    message = error.response.data.detail
                } else if (error.request) {
                    message = 'Something went wrong, Please try again later'
                } else {
                    message = 'Something went wrong, Please try again later'
                }
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                setDisabled(false)
            })
        }
    }

    const otpChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setOtp(value)
    }

    const newPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setNewPassword(value)
    }

    const cnfNewPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setCnfNewPassword(value)
    }

    const checkEmailForm = (
        <form id="verify" className={classes.form} onSubmit={handleSubmit} >
            <TextField
                required
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                inputProps={{ minLength: 5, maxLength: 50, type: 'email' }}
                onChange={emailChange}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled}
                className={classes.submit}
            >
                Submit
            </Button>
            <Grid container>
                <Grid item xs>
                    <MLink href="/#/login" variant="body2">
                        Login
                    </MLink>
                </Grid>
            </Grid>
        </form>
    )

    const resetPasswordForm = (
        <form id="reset" className={classes.form} onSubmit={handleReset} >
            <TextField
                required
                variant="outlined"
                margin="normal"
                fullWidth
                id="otp"
                label="OTP"
                name="otp"
                inputProps={{ minLength: 6, maxLength: 6, title: "Numbers only", pattern: "[0-9]{6}" }}
                onChange={otpChange}
                autoFocus
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                inputProps={{ minLength: 6, maxLength: 50 }}
                onChange={newPasswordChange}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                id="password"
                inputProps={{ minLength: 6, maxLength: 50 }}
                onChange={cnfNewPasswordChange}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled}
                className={classes.submit}
            >
                Submit
            </Button>
            <Grid container>
                <Grid item xs>
                    <MLink href="/#/login" variant="body2">
                        Login
                    </MLink>
                </Grid>
            </Grid>
        </form>
    )

    return (
        <div className="bg-auth">
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    Seva Sadhna - Admin
                    <Typography component="h1" variant="h5" className="mt-4 mb-1">
                        Forgot Password
                    </Typography>
                    {status ? resetPasswordForm : checkEmailForm}
                </div>
                <Box mt={8} className="pb-5">
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {new Date().getFullYear()} Seva Sadhna - Admin. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </div>
    );
}
