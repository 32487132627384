import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    ButtonGroup,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../../components/Table'
import axios from 'axios'
import { URL } from '../../../config'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListRoles = (props) => {
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const token = localStorage.getItem('ssaToken')
    const [roleId, setRoleId] = useState(null)
    const [open, setOpen] = React.useState(false)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "name",
            label: "Role Name",
            options: {
                sort: true,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = URL + '/roles'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(role => {
                var action = ''
                const url = 'roles/' + role.id
                if (role.editable) {
                    action = (
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Link to={url}>
                                <Tooltip title="Edit Role">
                                    <IconButton>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                            <Link>
                                <Tooltip title="Delete Role">
                                    <IconButton onClick={() => handleClickOpen(role.id)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </ButtonGroup>
                    )
                } else {
                    action = (
                        <ButtonGroup size="small" aria-label="small outlined button group" disabled>
                            <IconButton>
                                <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </ButtonGroup>
                    )
                }
                role.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                message = error.response.data.detail
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const deleteRole = (roleId) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/roles/' + roleId
        axios.delete(url, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Role Deleted')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                message = error.response.data.detail
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (roleId) => {
        setOpen(true)
        setRoleId(roleId)
    }

    const handleClose = () => {
        setOpen(false)
        setRoleId(null)
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">Roles</Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Link to="roles/add"><Button variant="contained" color="primary"><AddIcon />Add Role</Button></Link>
                    </Grid>
                </Grid>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Role"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Role ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteRole(roleId)} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="entry-div">
                <Table
                    title={'List Roles'}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
            </div>
        </div >
    )
}

export default ListRoles
