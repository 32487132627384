import React, { useState, useEffect } from 'react'
import { Alert } from '@material-ui/lab'
import Table from '../../components/Table'
import {
    ButtonGroup,
    IconButton,
    Tooltip,
    Typography,
    Breadcrumbs,
    LinearProgress,
    Link as MLink,
    Grid,
    Snackbar
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { apiGet } from '../../functionsAPI'

const List = (props) => {
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    // Alert
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }
    // Table Filter
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [tableData, setTableData] = useState([])

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                sort: true,
                // customBodyRender: (value, tableMeta, updateValue) => {
                //     return (value === '') ? '' : dateFormate(value)
                // }
            }
        },
        {
            name: "donor_name",
            label: "Donor Name",
            options: {
                sort: true,
            }
        },
        {
            name: "receipt_no",
            label: "Receipt No",
            options: {
                sort: true,
            }
        },
        {
            name: "type",
            label: "Donation Type",
            options: {
                sort: true,
            }
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        apiGet('/API_URL', params)
            .then((res) => {
                res.data.list.map(item => {
                    const viewUrl = 'donors/' + item.id
                    var action = (
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Tooltip title="View">
                                <IconButton onClick={() => props.history.push(viewUrl)}>
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    )
                    item.action = action
                    item.name = item.first_name + ' ' + item.last_name
                    return true
                })
                setCount(res.data.count)
                setTableData(res.data.list)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }
    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }
    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }
    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }
    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }
    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }
    // END: Table functions

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const tableTitle =
        <div className="before">
            <Typography variant="h6" gutterBottom>
                Listing Donations
            </Typography>
        </div>

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">Donations</Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </div>
            <br />
            <div className="entry-div">
                {progress}
                <Table
                    title={tableTitle}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
            </div>
        </div>
    )
}

List.defaultProps = {
    selectCustomer: null,
    isChild: false,
    onSelected: null,
}

export default List
