import React, { useState, useEffect } from 'react'
import { Alert, Autocomplete } from '@material-ui/lab'
import Table from '../components/Table'
import {
    ButtonGroup,
    Button,
    IconButton,
    Tooltip,
    Typography,
    makeStyles,
    Breadcrumbs,
    LinearProgress,
    Link as MLink,
    Grid,
    Snackbar,
    Drawer,
    TextField,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { dateToString } from '../functions'
import { apiGet } from '../functionsAPI'
import FilterListIcon from '@material-ui/icons/FilterList';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    filter: {
        padding: theme.spacing(4),
        width: '450px',
    },
}));

const Reports = (props) => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    // Alert
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }
    // Table Filter
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [tableData, setTableData] = useState([])

    const [sevaTypes, setSevaTypes] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [sevaType, setSevaType] = useState(null)
    const [state, setState] = useState(null)
    const [city, setCity] = useState(null)
    const [fromYear, setFromYear] = useState(null)
    const [toYear, setToYear] = useState(null)
    const [showClear, setShowClear] = useState(false)
    const [showClearDates, setShowClearDates] = useState(false)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                sort: true,
                // customBodyRender: (value) => {
                //     return (value === '') ? '' : dateFormate(value)
                // }
            }
        },
        {
            name: "name",
            label: "Donor Name",
            options: {
                sort: true,
            }
        },
        {
            name: "mobile",
            label: "Mobile",
            options: {
                sort: true,
            }
        },
        {
            name: "receipt_no",
            label: "Receipt No",
            options: {
                sort: true,
            }
        },
        {
            name: "city",
            label: "City",
            options: {
                sort: true,
            }
        },
        {
            name: "state",
            label: "State",
            options: {
                sort: true,
            }
        },
        {
            name: "type",
            label: "Donation Type",
            options: {
                sort: true,
            }
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all', seva_type_id = 'all', state_id = 'all', city_id = 'all', from_date = 'all', to_date = 'all') => {
        setLoading(true)
        const _seva_type_id = (seva_type_id === null) ? 'all' : sevaType
        const _state_id = (state_id === null) ? 'all' : state
        const _city_id = (city_id === null) ? 'all' : city
        const _from_date = (from_date === 'all' || from_date === null) ? 'all' : dateToString(fromYear)
        const _to_date = (to_date === 'all' || to_date === null) ? 'all' : dateToString(toYear)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search,
            seva_type_id: _seva_type_id,
            state_id: _state_id,
            city_id: _city_id,
            from_date: _from_date,
            to_date: _to_date
        }
        apiGet('/API_URL', params)
            .then((res) => {
                res.data.list.map(item => {
                    const viewUrl = 'donations/' + item.id
                    const action = (
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Tooltip title="View">
                                <IconButton onClick={() => props.history.push(viewUrl)}>
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    )
                    item.action = action
                    return true
                })
                setCount(res.data.count)
                setTableData(res.data.list)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }
    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }
    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }
    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }
    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }
    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }
    // END: Table functions

    // Filters
    const [openFilter, setOpenFilter] = useState(false)
    const toggleDrawer = () => {
        setOpenFilter(!openFilter)
    }
    const handleFromDateFilterChange = (selectedDate) => {
        const date = moment(selectedDate)
        setFromYear(date)
        if (toYear === null) {
            setToYear(moment())
        }
        setShowClearDates(true)
    }
    const handleToDateFilterChange = (selectedDate) => {
        const date = moment(selectedDate)
        setToYear(date)
    }
    const clearFilters = () => {
        toggleDrawer()
        setSevaType(null)
        setState(null)
        setCity(null)
        setFromYear(null)
        setToYear(null)
        setShowClear(false)
        setShowClearDates(false)
        setStart(0)
        getList(0, limit, sortBy, order, search, null, null, null, null, null)
    }

    const clearDates = () => {
        setFromYear(null)
        setToYear(null)
        setShowClearDates(false)
    }

    useEffect(() => {
        if (sevaType || state || city || toYear) {
            setShowClear(true)
        } else {
            setShowClear(false)
        }
        // eslint-disable-next-line
    }, [sevaType, state, city, toYear])

    const tableTitle =
        <div className="before">
            <Typography variant="h6" gutterBottom>
                Donation Reports
            </Typography>
        </div>

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">Donation Reports</Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Button variant="contained" color="primary" onClick={toggleDrawer} className='mr-2'><FilterListIcon className='mr-2' />Filters</Button>
                    </Grid>
                </Grid>
            </div>
            <br />
            {/* Filter drawer */}
            <Drawer anchor='right' open={openFilter} onClose={toggleDrawer}>
                <div className={classes.filter}>
                    <Grid container
                        direction="column"
                        spacing={2}
                    >
                        <Grid item >
                            <Grid container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-start"
                            >
                                <Grid item >
                                    <Tooltip title="Close"><IconButton onClick={toggleDrawer}><CloseIcon /></IconButton></Tooltip>
                                    &nbsp;
                                    <Button variant="contained" color="primary" onClick={() => {
                                        getList(start, limit, sortBy, order, search, fromYear, toYear)
                                        toggleDrawer()
                                    }}>
                                        Apply
                                    </Button>
                                    {showClear ? (
                                        <>
                                            &nbsp;
                                            &nbsp;
                                            <Button variant="contained" color="secondary" onClick={clearFilters}>
                                                Clear
                                            </Button>
                                        </>
                                    ) : ''}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={sevaTypes}
                                value={sevaType}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Seva Type" variant="outlined" />}
                                onChange={(e, value) => setSevaType(value)}
                            />
                            <Autocomplete
                                options={states}
                                value={state}
                                autoHighlight
                                className="mt-4"
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="State" variant="outlined" />}
                                onChange={(e, value) => setState(value)}
                            />
                            <Autocomplete
                                options={cities}
                                value={city}
                                autoHighlight
                                className="mt-4"
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="City" variant="outlined" />}
                                onChange={(e, value) => setCity(value)}
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label="Start Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={fromYear}
                                    className="mt-4"
                                    onChange={handleFromDateFilterChange}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label="End Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={toYear}
                                    className="mt-4"
                                    onChange={handleToDateFilterChange}
                                />
                            </MuiPickersUtilsProvider>
                            {showClearDates ? (
                                <Button variant="contained" color="secondary" className='mt-4' onClick={clearDates}>
                                    Clear dates
                                </Button>
                            ) : ''}
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
            <div className="entry-div">
                {progress}
                <Table
                    title={tableTitle}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
            </div>
        </div>
    )
}

export default Reports