import React, { useEffect, useState } from 'react'
import Table from '../../components/Table'
import {
    Breadcrumbs,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Tooltip,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { apiDelete, apiGet } from '../../functionsAPI';
import VisibilityIcon from '@material-ui/icons/Visibility'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ViewDonor = (props) => {
    const donorId = props.match.params.donorId
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    // Alerts
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const handleAlertClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }
    const [donor, setDonor] = useState({})
    const [openDeleteDonor, setOpenDeleteDonor] = useState(false)

    // Table Filter
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [tableData, setTableData] = useState([])

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                sort: true,
                // customBodyRender: (value, tableMeta, updateValue) => {
                //     return (value === '') ? '' : dateFormate(value)
                // }
            }
        },
        {
            name: "receipt_no",
            label: "Receipt No",
            options: {
                sort: true,
            }
        },
        {
            name: "type",
            label: "Donation Type",
            options: {
                sort: true,
            }
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        apiGet('/API_URL', params)
            .then((res) => {
                res.data.list.map(item => {
                    const viewUrl = 'donors/' + item.id
                    var action = (
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Tooltip title="View">
                                <IconButton onClick={() => props.history.push(viewUrl)}>
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    )
                    item.action = action
                    item.name = item.first_name + ' ' + item.last_name
                    return true
                })
                setCount(res.data.count)
                setTableData(res.data.list)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }
    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }
    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }
    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }
    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }
    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }
    // END: Table functions

    const getDonor = () => {
        setLoading(true)
        apiGet('/API_HERE/' + donorId)
            .then((res) => {
                setDonor(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    useEffect(() => {
        getDonor()
        getList()
        // eslint-disable-next-line
    }, [])

    // Delete
    const handleOpenDelete = () => {
        setOpenDeleteDonor(true)
    }
    const handleCloseDelete = () => {
        setOpenDeleteDonor(false)
    }
    const deleteDonor = () => {
        setLoading(true)
        apiDelete('/API_HERE/' + donorId)
            .then((res) => {
                setAlertType('success')
                setAlertMessage('Deleted successfully')
                setShowAlert(true)
                setOpenDeleteDonor(false)
                props.history.push('/dashboard/donors')
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    const tableTitle =
        <div className="before">
            <Typography variant="h6" gutterBottom>
                Rakesh's Donations
            </Typography>
        </div>

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">View Donor</Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                            <MLink color="inherit" href="/#/dashboard/donors">
                                Donors
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Link to={donorId + '/edit'}><Button variant="contained" color="primary" className="mr-3"><EditIcon className='mr-2' />Edit</Button></Link>
                        <Button variant="contained" color="secondary" onClick={handleOpenDelete}><DeleteIcon className='mr-2' />Delete</Button>
                    </Grid>
                </Grid>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="entry-div">
                {progress}
                <Card>
                    <CardContent>
                        <div className="before">
                            <h2 className="form-heading">Donor Details</h2>
                        </div>
                        <hr />
                        <table className="details">
                            <tr>
                                <th>First Name</th>
                                <td>Rakesh</td>
                                <th>Last Name</th>
                                <td>Chauhan</td>
                            </tr>
                            <tr>
                                <th>State</th>
                                <td>Gujarat</td>
                                <th>City</th>
                                <td>Bhuj</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>Near Bus Stand</td>
                                <th>Pincode</th>
                                <td>370001</td>
                            </tr>
                            <tr>
                                <th>Mobile</th>
                                <td><a href="tel:+91 98765 43215">+91 98765 43215</a></td>
                                <th>PAN Card Number</th>
                                <td>ABCDE4253F</td>
                            </tr>
                        </table>
                    </CardContent>
                </Card>
                <div className="mt-4"></div>
                <Table
                    title={tableTitle}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
                {/* Delete Donor dialog */}
                <Dialog
                    open={openDeleteDonor}
                    onClose={handleCloseDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {progress}
                    <DialogTitle id="alert-dialog-title">Delete Donor</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the Donor?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDelete} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={deleteDonor} variant="contained" color="secondary" disabled={loading}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default ViewDonor
