import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { URL } from '../config'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import ProfileMenu from './ProfileMenu'
import SideNav from './SideNav'
import {
    AppBar,
    CssBaseline,
    Drawer,
    Grid,
    Hidden,
    IconButton,
    Snackbar,
    Toolbar,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
// Dashboard
import Dashboard from '../pages/Dashboard'
// Master
import Master from '../pages/Master'
// Donors
import ListDonors from '../pages/donors/List'
import ViewDonor from '../pages/donors/View'
import EditDonor from '../pages/donors/Edit'
// Donations
import ListDonations from '../pages/donations/List'
import ViewDonation from '../pages/donations/View'
// Reports
import Reports from '../pages/Reports'
// Settings: Users
import ListUsers from '../pages/settings/users/ListUsers'
import AddUser from '../pages/settings/users/AddUser'
import UpdateUser from '../pages/settings/users/UpdateUser'
import ResetPassword from '../pages/settings/users/ResetPassword'
// Settings: Roles
import ListRoles from '../pages/settings/roles/ListRoles'
import AddRole from '../pages/settings/roles/AddRole'
import UpdateRole from '../pages/settings/roles/UpdateRole'
// Settings: Profile
import Profile from '../pages/settings/users/profile/Profile'
import EditProfile from '../pages/settings/users/profile/EditProfile'
// Other
import Unauthorized from '../pages/authentication/Unauthorized'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function ResponsiveDrawer(props) {
    const { window } = props
    const classes = useStyles()
    const theme = useTheme()
    const token = localStorage.getItem('ssaToken')
    const operations = JSON.parse(localStorage.getItem('ssaOperations'))
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')

    const [mobileOpen, setMobileOpen] = useState(false);

    const getOperations = () => {
        if (token !== null) {
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = URL + '/operations'
            axios.get(url, headerConfig).then(response => {
                const operations = response.data.operations
                localStorage.setItem('ssaOperations', JSON.stringify(operations))
            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 402) {
                        localStorage.clear()
                        props.history.push('/login')
                    } else {
                        console.log(error.response)
                    }
                } else if (error.request) {
                    console.log(error.request)
                } else {
                    console.log(error)
                }
            })
        }
    }

    useEffect(() => {
        if (token === null) {
            var location = '/login'
            props.history.push(location)
        }
        getOperations()
        // eslint-disable-next-line
    }, [])

    const checkOperations = (operation) => {
        const status = operations.includes(operation)
        if (status === false) {
            setAlertType('error')
            setAlertMessage('You don\'t have permission.')
            setShowAlert(true)
        }
        return status
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Grid container spacing={3}>
                        <Grid item xs={11}></Grid>
                    </Grid>
                    <ProfileMenu />
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <SideNav />
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <div className="bg-1 pl-3 pt-3">
                            Seva Sadhna
                        </div>
                        <SideNav />
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    {/* Dashboard */}
                    <Route exact path="/dashboard" component={Dashboard} />
                    {/* Master */}
                    <Route exact path="/dashboard/master"
                        render={(props) => {
                            var status = checkOperations('List Master')
                            if (status === true) {
                                return (<Master {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    {/* Donors */}
                    <Route exact path="/dashboard/donors"
                        render={(props) => {
                            return (<ListDonors {...props} />)
                            // var status = checkOperations('List Donors')
                            // if (status === true) {
                            //     return (<ListDonors {...props} />)
                            // }
                            // else {
                            //     return (<Redirect to='/dashboard/unauthorized' />)
                            // }
                        }}
                    />
                    <Route exact path="/dashboard/donors/:donorId"
                        render={(props) => {
                            return (<ViewDonor {...props} />)
                            // var status = checkOperations('View Donor')
                            // if (status === true) {
                            //     return (<ViewDonor {...props} />)
                            // }
                            // else {
                            //     return (<Redirect to='/dashboard/unauthorized' />)
                            // }
                        }}
                    />
                    <Route exact path="/dashboard/donors/:donorId/edit"
                        render={(props) => {
                            return (<EditDonor {...props} />)
                            // var status = checkOperations('Update Donor')
                            // if (status === true) {
                            //     return (<EditDonor {...props} />)
                            // }
                            // else {
                            //     return (<Redirect to='/dashboard/unauthorized' />)
                            // }
                        }}
                    />
                    {/* Donations */}
                    <Route exact path="/dashboard/donations"
                        render={(props) => {
                            return (<ListDonations {...props} />)
                            // var status = checkOperations('List Donations')
                            // if (status === true) {
                            //     return (<ListDonations {...props} />)
                            // }
                            // else {
                            //     return (<Redirect to='/dashboard/unauthorized' />)
                            // }
                        }}
                    />
                    <Route exact path="/dashboard/donations/:donationId"
                        render={(props) => {
                            return (<ViewDonation {...props} />)
                            // var status = checkOperations('View Donation')
                            // if (status === true) {
                            //     return (<ViewDonation {...props} />)
                            // }
                            // else {
                            //     return (<Redirect to='/dashboard/unauthorized' />)
                            // }
                        }}
                    />
                    <Route exact path="/dashboard/reports"
                        render={(props) => {
                            return (<Reports {...props} />)
                            // var status = checkOperations('View Reports')
                            // if (status === true) {
                            //     return (<Reports {...props} />)
                            // }
                            // else {
                            //     return (<Redirect to='/dashboard/unauthorized' />)
                            // }
                        }}
                    />
                    {/* Settings: Users */}
                    <Route exact path="/dashboard/settings/users"
                        render={(props) => {
                            var status = checkOperations('List Users')
                            if (status === true) {
                                return (<ListUsers {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/add"
                        render={(props) => {
                            var status = checkOperations('Add User')
                            if (status === true) {
                                return (<AddUser {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/:userId"
                        render={(props) => {
                            var status = checkOperations('Update User')
                            if (status === true) {
                                return (<UpdateUser {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/reset-password/:userId"
                        render={(props) => {
                            var status = checkOperations('Reset Password')
                            if (status === true) {
                                return (<ResetPassword {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    {/* Settings: Roles */}
                    <Route exact path="/dashboard/settings/roles"
                        render={(props) => {
                            var status = checkOperations('List Roles')
                            if (status === true) {
                                return (<ListRoles {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles/add"
                        render={(props) => {
                            var status = checkOperations('Add Role')
                            if (status === true) {
                                return (<AddRole {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles/:roleId"
                        render={(props) => {
                            var status = checkOperations('Update Role')
                            if (status === true) {
                                return (<UpdateRole {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    {/* Settings: Profile */}
                    <Route exact path="/dashboard/profile" component={Profile} />
                    <Route exact path="/dashboard/profile/edit" component={EditProfile} />
                    {/* Other */}
                    <Route exact path="/dashboard/unauthorized" component={Unauthorized} />
                </Switch>
            </main>
        </div>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
