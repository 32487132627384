import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Login from './pages/authentication/Login'
import ForgotPassword from './pages/authentication/ForgotPassword'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'

function App() {
    const mainPrimaryColor = '#097CD4'
    const mainSecondaryColor = '#097CD4'

    const themeColor = createTheme({
        typography: {
            "fontFamily": `"Lato", "Roboto", "Helvetica", "Arial", sans-serif`,
        },
        palette: {
            type: 'light',
            primary: {
                main: mainPrimaryColor
            },
            secondary: {
                main: mainSecondaryColor
            }
        }
    })

    return (
        <ThemeProvider theme={themeColor}>
            <div className="noSelect">
                <Router>
                    <Route path='/login' component={Login} />
                    <Switch>
                        <Route exact path='/' render={(props) => {
                            const token = localStorage.getItem('ssaToken')
                            if (token !== null) {
                                props.history.push('/dashboard')
                            }
                            else {
                                props.history.push('/login')
                            }
                        }} />
                        <Route path='/forgot' component={ForgotPassword} />
                        <Route
                            path='/dashboard'
                            render={(props) => (
                                <Layout {...props} />
                            )}
                        />
                    </Switch>
                </Router>
            </div>
        </ThemeProvider>
    )
}

export default App
