import React, { useState, useEffect } from 'react'
import { Alert } from '@material-ui/lab'
import { apiGet, apiPut, apiDelete } from '../../functionsAPI';
import Table from '../../components/Table'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {
    ButtonGroup,
    Button,
    IconButton,
    Tooltip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    LinearProgress,
    TextField,
    Snackbar
} from '@material-ui/core'
import { trim } from '../../functions';

const States = () => {
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    // Alert
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }
    // Table Filter
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [tableData, setTableData] = useState([])
    // Other
    const [stateId, setStateId] = useState(null)
    const [stateName, setStateName] = useState('')

    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const handleStateChange = (e) => {
        var value = e.target.value
        setStateName(value)
    }
    // Table functions
    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "name",
            label: "State Name",
            options: {
                sort: true,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]
    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        apiGet('/API_URL', params)
            .then((res) => {
                res.data.list.map(item => {
                    const action = (
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Tooltip title="Edit">
                                <IconButton onClick={() => handleEditOpen(item.id)}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton onClick={() => handleDeleteOpen(item.id)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    )
                    item.action = action
                    return true
                })
                setCount(res.data.count)
                setTableData(res.data.list)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }
    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])
    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }
    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }
    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }
    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }
    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }
    // END: Table functions

    // Edit
    const handleEditOpen = (id) => {
        setLoading(true)
        setStateId(id)
        setOpenEdit(true);
        apiGet('/API_URL/' + id)
            .then((res) => {
                setStateName(res.data.name)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    };
    const handleEditClose = () => {
        setStateId(null)
        setStateName('')
        setOpenEdit(false);
    };
    // Submit Edit Form
    const onEditSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            name: trim(stateName),
        }
        const url = '/API_URL/' + stateId
        apiPut(url, data)
            .then((res) => {
                setAlertType('success')
                setAlertMessage('Edited Successfully')
                handleEditClose();
                setShowAlert(true)
                getList()
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    // Delete Model
    const handleDeleteOpen = (id) => {
        setStateId(id)
        setOpenDelete(true);
    };
    const handleDeleteClose = () => {
        setStateId(null)
        setOpenDelete(false);
    };
    // Delete
    const deleteItem = (id) => {
        setLoading(true)
        apiDelete('/API_URL/' + id)
            .then((res) => {
                setAlertType('success')
                setAlertMessage('Deleted successfully')
                setOpenDelete(false)
                setShowAlert(true)
                getList()
            })
            .catch((err) => {
                console.log(err);
                setAlertType('error')
                setAlertMessage(err)
                setShowAlert(true)
                setLoading(false)
            });
    }

    const tableTitle = <div className="before">
        <Typography variant="h6" gutterBottom>
            Listing States
        </Typography>
    </div>

    return (
        <div className="entry-div">
            {progress}
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Table
                title={tableTitle}
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
            {progress}
            <Dialog open={openEdit} onClose={handleEditClose} className="form-action" aria-labelledby="form-dialog-title">
                {progress}
                <DialogTitle id="form-dialog-title">Edit</DialogTitle>
                <form onSubmit={onEditSubmit}>
                    <DialogContent dividers>
                        <TextField
                            required
                            fullWidth
                            label="State Name"
                            value={stateName}
                            variant="outlined"
                            onChange={handleStateChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={openDelete} onClose={handleDeleteClose} className="form-action" aria-labelledby="form-dialog-title">
                {progress}
                <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Are you sure you want to delete?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(stateId)} variant="contained" color="secondary">
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default States
