import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ResetPassword = (props) => {
    const userId = props.match.params.userId
    const token = localStorage.getItem('ssaToken')
    const [newPassword, setNewPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')

    const getUser = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/users/' + userId
        axios.get(url, headerConfig).then(response => {
            const user = response.data
            setFirstName(user.first_name)
            setLastName(user.last_name)
            setEmail(user.email)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                message = error.response.data.detail
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    useEffect(() => {
        getUser()
        // eslint-disable-next-line
    }, [])

    const handleNewPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setNewPassword(value)
    }

    const handleConfirmPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setConfirmPassword(value)
    }

    const onPasswordUpdate = (e) => {
        e.preventDefault()
        if (newPassword !== confirmPassword) {
            setAlertMessage('Passwords are not same')
            setAlertType('error')
            setShowAlert(true)
        }
        else {
            setDisabled(true)
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = URL + '/users/' + userId + '/reset-password'
            const data = {
                new_password: newPassword
            }
            axios.put(url, data, headerConfig).then(response => {
                setAlertMessage('Password changed.')
                setAlertType('success')
                setShowAlert(true)
                setDisabled(false)
            }).catch(error => {
                let message = 'Try after some time.'
                if (error.response) {
                    message = error.response.data.detail
                    console.log(error.response)
                } else if (error.request) {
                    console.log(error.request)
                } else {
                    console.log(error)
                }
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
            })
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">Reset Password</Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/settings/users">
                        Users
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Reset Password</h2>
                    </div>
                    <hr />
                    <form id="pass" name="pass" onSubmit={onPasswordUpdate}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={3}>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    name="firstName"
                                    value={firstName}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    value={lastName}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    value={email}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    key="newPassword"
                                    required
                                    fullWidth
                                    name="newPassword"
                                    label="New Password"
                                    type="password"
                                    id="newPassword"
                                    inputProps={{ minLength: 6, maxLength: 50 }}
                                    onChange={handleNewPasswordChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    key="confirmPassword"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    id="confirmPassword"
                                    inputProps={{ minLength: 6, maxLength: 50 }}
                                    onChange={handleConfirmPasswordChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/settings/users">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={disabled}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div >
    )
}

export default ResetPassword
