import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    FormControlLabel,
    FormGroup,
    Grid,
    Link as MLink,
    Snackbar,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import MuiAlert from '@material-ui/lab/Alert'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UpdateRole = (props) => {
    const roleId = props.match.params.roleId
    const [name, setName] = useState('a')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [operations, setOperations] = useState([])
    const [state, setState] = useState({})
    const token = localStorage.getItem('ssaToken')

    const getOperations = () => {
        const url = URL + '/operations/all'
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        axios.get(url, headerConfig).then(response => {
            setOperations(response.data)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                message = error.response.data.detail
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const getRole = () => {
        const url = URL + '/roles/' + roleId
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        axios.get(url, headerConfig).then(response => {
            setName(response.data.name)
            var cb = {}
            response.data.operations.map(operation => {
                cb[operation] = true
                return true
            })
            setState({ ...state, ...cb })
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                message = error.response.data.detail
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    useEffect(() => {
        getOperations()
        getRole()
        // eslint-disable-next-line
    }, [])

    const nameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        var operations = []
        for (var key in state) {
            if (state[key] === true) {
                operations.push(key)
            }
        }
        if (operations.length === 0) {
            setAlertType('error')
            setAlertMessage('Select at least one operation.')
            setShowAlert(true)
        }
        else {
            const data = {
                name,
                operations
            }
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = URL + '/roles/' + roleId
            axios.put(url, data, headerConfig).then(response => {
                setAlertType('success')
                setAlertMessage('Role added')
                setShowAlert(true)
                props.history.push('/dashboard/settings/roles')
            }).catch(error => {
                let message = 'Try after some time.'
                if (error.response) {
                    message = error.response.data.detail
                    console.log(error.response)
                } else if (error.request) {
                    console.log(error.request)
                } else {
                    console.log(error)
                }
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
            })
        }

    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setState({ ...state, [name]: value })
    }


    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">Update Role</Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/settings/roles">
                        Roles
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Update Role</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    fullWidth
                                    id="name"
                                    label="Role Name"
                                    name="name"
                                    value={name}
                                    inputProps={{ minLength: 2, maxLength: 50 }}
                                    onChange={nameChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {operations.map((operation, index) => {
                                    return (
                                        <Accordion key={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={operation.id}
                                                id={operation.id}
                                            >
                                                <Typography>{operation.name}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid item xs={12}>
                                                    <FormGroup row>
                                                        {
                                                            operation.operations.map((child, index) => {
                                                                return (
                                                                    <Grid key={index} item xs={2}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <div>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name={child.id}
                                                                                        checked={state[child.id]}
                                                                                        onChange={handleInputChange}
                                                                                    />
                                                                                    &nbsp;
                                                                                </div>
                                                                            }
                                                                            label={child.name}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </FormGroup>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/settings/roles">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div >
    )
}

export default UpdateRole
