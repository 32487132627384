import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
    Box,
    Button,
    Container,
    CssBaseline,
    // Link as MLink,
    TextField,
    Snackbar,
    Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { URL } from '../../config.js'
import axios from 'axios'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = (props) => {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(false)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)

    useEffect(() => {
        const token = localStorage.getItem('ssaToken')
        if (token !== null) {
            var location = '/dashboard'
            props.history.push(location)
        }
        // eslint-disable-next-line
    }, [])

    const emailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
    }

    const passwordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setPassword(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setDisabled(true)
        const data = {
            email,
            password,
        }
        const url = URL + '/login'
        axios.post(url, data).then(response => {
            const user = response.data
            localStorage.setItem('ssaToken', user.token)
            localStorage.setItem('ssaUserPreferences', JSON.stringify(user.user_preferences))
            localStorage.setItem('ssaUser', JSON.stringify(user))
            var location = '/dashboard'
            props.history.push(location)
        }).catch(error => {
            let message = 'Something went wrong, Please try again later'
            if (error.response) {
                // console.log(error.response.data.detail);
                // setError(error.response.data.detail)
                message = error.response.data.detail
            } else if (error.request) {
                // console.log(error.request)
                // setError('Try after some time.')
                message = 'Something went wrong, Please try again later'
            } else {
                // console.log(error)
                // setError('Try after some time.')
                message = 'Something went wrong, Please try again later'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setDisabled(false)
            localStorage.clear()
        })
    }

    // Alert
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div className="bg-auth">
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h4" className="mt-4 mb-1">
                        Seva Sadhna - Admin
                    </Typography>
                    <Typography component="h2" variant="h5" className="mt-4 mb-1">
                        Sign in
                    </Typography>
                    <form className={classes.form} onSubmit={onSubmit} >
                        <TextField
                            required={true}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            value={email}
                            inputProps={{ minLength: 5, maxLength: 50, type: 'email' }}
                            onChange={emailChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={password}
                            inputProps={{ minLength: 6, maxLength: 50 }}
                            onChange={passwordChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={disabled}
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                        {/* <MLink href="/#/forgot" variant="body2">
                            Forgot password?
                        </MLink> */}
                    </form>
                </div>
                <Box mt={8} className="pb-5">
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {new Date().getFullYear()} Seva Sadhna - Admin. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </div>
    )
}

export default Login
